export const XAVAGE = {
  es: {
    location: "Ficha Xavage",
    titulo: "TOUR XAVAGE ALL INCLUSIVE",
    imagen: "slide-xavage.jpg",
    hybridConf: {
      baseProduct: "XVEAI_0_18",
      upgrades: {
        T: "XTXVAI_1_18",
        P: "PAQFP_0_5_0"
      }
    },
    info: {
      titleDescripcion:
        "XAVAGE CANCÚN ES LA MANERA MÁS ORIGINAL DE VIVIR LA AVENTURA",
      contentDescripcion: [
        "<strong>Xavage by Xcaret</strong> es el nuevo parque en Cancún y, el único, que reúne seis diferentes actividades de aventura en un mismo lugar para todos los gustos.",
        "Solo aquí puedes vivir la experiencia del Rafting en el circuito de ríos rápidos artificial más largo del mundo. Escalar por un Ropes Course con obstáculos suspendidos hasta 36 metros de altura que te sorprenderán; manejar un Monster Truck de diseño exclusivo de parque Xavage por divertidos obstáculos rocosos y sentir la emoción de un Jet Boat que además de ir a toda velocidad, también dará giros 360° por angostos canales.",
        "En el parque Xavage pensamos también en ti, que recién te animas a la aventura, para que disfrutes de un paseo en Kayak, el único por canales de Cancún y la Riviera Maya, y una tirolesa en la que volarás como ave.",
        "Para los niños de 5 a 11 años, hay un área especial en Xorros y Cachorros, con chapoteadero, resbaladillas y los divertidos tirachorros donde no pararán de jugar.",
        "<strong>Además ¡nuestras entradas te incluyen un snack, buffet y bebidas sin alcohol ilimitadas!</strong>"
      ]
    },
    includes: {
      titleIncluye: "Incluye",
      contentIncluye: [
        "Kayak.",
        "Ropes Course.",
        "Rafting.",
        "Jet Boat.",
        "Tirolesa Xavage.",
        "Monster Truck.",
        "Xorros y Cachorros.",
        "¡Puedes hacerlas las veces que quieras!",
        "Snack (pizzas, jugos y licuados).",
        "Buffet con bebidas sin alcohol ilimitadas.",
        "Equipos de seguridad para todas las actividades.",
        "Uso de casillero.",
        "Estacionamiento gratuito.",
        "Servicios",
        "Sanitarios, vestidores y regaderas.",
        "WiFi, cajero automático y tiendas (con costo adicional)."
      ]
    },
    recommendations: {
      titleRecommendations: "Recomendaciones",
      contentRecommendations: [
        "Recuerda traer impreso el comprobante de tu compra.",
        "Usa traje de baño y zapatos cerrados que se puedan mojar.",
        "Trae una muda de ropa extra y toalla.",
        "No podrás realizar ninguna actividad una vez que hayas consumido alcohol (bebidas con alcohol tienen costo extra).",
        "En todas las actividades deberás tener las manos completamente libres, por lo que no se permite el uso de cámara, celular, selfie sticks o accesorios que lo impidan.",
        "Tus bloqueadores, repelentes y bronceadores deben ser libres de químicos para su uso en el parque.",
        "Lleva efectivo o tarjeta para comprar souvenirs o adquirir las fotos de tu visita."
      ]
    },
    important: {
      titleImportant: "Información Importante",
      contentImportant: [
        "Niños menores de 12 años con una altura mayor a 1.40 m también deberán presentar identificación para comprobar su edad.",
        "Algunas actividades tienen limitantes para futuras mamás.",
        "Ciertas actividades no son recomendables si tienes problemas cardíacos, miedo a las alturas, una cirugía reciente, dolor de espalda o te mareas fácilmente.",
        "La transportación desde Cancún, Playa del Carmen y la Riviera Maya estará incluida solo si la agregas al momento de tu compra.",
        "Todas las actividades están sujetas a las condiciones del clima.",
        "Debes verificar primero los requerimientos de cada actividad.",
        "Las reservaciones canceladas con más de dos días de antelación a la fecha de llegada están sujetas a un cargo del 10% por gastos administrativos. De 2 a 0 días antes de la fecha de llegada, o en caso de no llegar, no serán reembolsables.",
        "No se permiten cambios de fecha el mismo día de tu visita."
      ]
    }
  },
  en: {
    location: "Xcaret",
    titulo: "XCARET TOUR",
    imagen: "slide-xcaret.jpg",
    info: {
      titleDescripcion:
        "Fun, nature and culture for all the family in Xcaret Park",
      contentDescripcion: [
        "Xcaret its a natural park with more than 50 attractions for all ages, perfect to go with all the family.",
        "Here you can swim in underground rivers, pasear por senderos en medio de la jungla y recorrer cuevas misteriosas. Descubre nuevos colores en el Mariposario y los misterios del fondo marino en el Acuario de Arrecife de Coral. Admira de cerca venados, jaguares, flamingos y aves exóticas.",
        "En Xcaret México hay diversos atractivos para visitar, como los vestigios precolombinos, el Museo de Arte Popular Mexicano, una clásica hacienda henequenera y la capilla de Guadalupe.",
        "Además disfruta de la cultura mexicana con las danzas prehispánicas, la charrería y la magnífica presentación de Xcaret México Espectacular, con más de 300 artistas en escena.",
        "En Xcaret Riviera Maya hay una amplia variedad de restaurantes para elegir. Y para tu mayor comodidad, tenemos áreas de descanso, vestidores, duchas y sanitarios, ubicados en distintas áreas del parque.",
        "¡Ven a Xcaret y vive con tu familia la experiencia más inolvidable de sus vacaciones!"
      ]
    },
    includes: {
      titleIncluye: "Includes",
      contentIncluye: [
        "Access to the beach, inlet and natural pools. ",
        "Actividades acuáticas en tres ríos subterráneos (incluye bolsa de seguridad para el recorrido).",
        "Espectáculos y exhibiciones (Charrería, Voladores de Papantla y Danzas Prehispánicas).",
        "La nueva presentación de “Xcaret México Espectacular”.",
        "Visitas al Acuario de Arrecife Coral, el Mariposario, el Aviario y la Isla de los Jaguares.",
        "Acceso a la taquilla especial por comprar en línea.",
        "Chalecos salvavidas y llantas flotantes.",
        "Camastros, hamacas y áreas de descanso.",
        "Sanitarios, vestidores y regaderas.",
        "Estacionamiento gratuito.",
        "Otros servicios (WiFi, cajeros automáticos y tiendas).",
        "Tu Tour incluye transportación solo si la agregas al momento de tu compra."
      ]
    },
    recommendations: {
      titleRecommendations: "Recommendations",
      contentRecommendations: [
        "¡All the photos of your experience at an special price! By including your photo pass before your visit you get an exclusive price.",
        "Recuerda traer impreso el comprobante de compra de tu admisión al Parque.",
        "Usa ropa cómoda de playa y traje de baño, trae una muda extra.",
        "Si el tour es con transportación, confirma la hora y lugar de salida .",
        "¡Ayúdanos a cuidar y preservar nuestro ecosistema! Para no afectar el balance natural del ecosistema, en el Parque solo está permitido el uso de bloqueadores y bronceadores que contengan óxido de titanio y óxido de zinc. En caso de no cumplir con este requisito, a la entrada del Parque encontrarás un módulo de información donde podrás intercambiar tu bloqueador por una muestra libre de químicos; tu bloqueador te será devuelto a la salida.",
        "Lleva efectivo o tarjeta para comprar souvenirs, realizar alguna actividad opcional o adquirir las fotos de tu visita."
      ]
    },
    important: {
      titleImportant: "Important Information",
      contentImportant: [
        "Xcaret Park opens 365 days a year from 8:30 a.m. to 9:30 p.m.",
        "Niños menores de 4 años entran gratis.",
        "Niños de 5 a 11 años tienen 25% de descuento sobre el precio de adulto (indispensable presentar identificación en las taquillas del Parque).",
        "Niños mayores a 1.40 m pero menores de 12 años, deberán presentar identificación para comprobar su edad.",
        "Las reservaciones canceladas con más de 2 días de antelación a la fecha de llegada, están sujetas a un cargo del 10% por gastos administrativos; de 2 a 0 días antes de la fecha de llegada, o en caso de no llegar, no serán reembolsables.",
        "No es posible hacer el cambio de fecha el mismo día de tu visita.",
        "La renta del equipo de snorkel tiene un costo de $10 USD (se requiere de un depósito reembolsable de $ 25 USD).",
        "Para los visitantes con admisión Xcaret Plus (con alimentos) el uso del equipo de snorkel está incluido (requiere un depósito reembolsable de $ 10 USD)."
      ]
    }
  }
};
