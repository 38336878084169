export const HOME_TEXTS = {
  es: {
    productsTitle: "PARQUES Y TOURS",
    productsPromo: "¡Haz de tu experiencia algo único con nuestros parques!",
    atracciones: [
      {
        imagen: "xcaret.jpg",
        titulo: "TOUR XCARET",
        descripcion: [
          "El parque natural Xcaret te espera con los brazos abiertos. Sumérgete en sus ríos subterráneos de refrescantes aguas o adéntrate por los senderos de la jungla y descubre diversas especies de la flora y fauna local.",
          "¡Quédate en la noche a Xcaret México Espectacular! Una fiesta de música y color con más de 300 artistas que muestran lo más representativo de la cultura mexicana."
        ],
        url_info: "tour-xcaret",
        hybridConf: {
          baseProduct: "XCEPA_0_1",
          upgrades: {
            C: "XCEPL_0_1",
            T: "XTXCPA_1_1",
            P: "PAQFP_0_5_0"
          },
          combinations: {
            CT: "XTXCPL_1_1"
          }
        }
      },
      {
        imagen: "xelha.jpg",
        titulo: "TOUR XELHA",
        descripcion: [
          "Visita Xel-Há para pasar un día en familia.",
          "Disfruta alimentos y bebidas ilimitados, así como hacer snorkel a tu ritmo y todo el tiempo que quieras, recorre sus senderos y sube al faro de 40 metros de altura y deslízate hasta una poza natural. "
        ],
        url_info: "tour-xelha",
        hybridConf: {
          baseProduct: "XHETI_0_3",
          upgrades: {
            T: "XTXHTI_1_3",
            P: "PAQFP_0_5_0"
          }
        }
      },
      {
        imagen: "xplor.jpg",
        titulo: "TOUR XPLOR",
        descripcion: [
          "¡Vive un día lleno de emociones en Xplor!",
          "Deslízate en las tirolesas más altas y espectaculares de la Riviera Maya, nada por ríos subterráneos y explora caminos en la selva a bordo de un vehículo anfibio. "
        ],
        url_info: "tour-xplor",
        hybridConf: {
          baseProduct: "XPETI_0_2",
          upgrades: {
            T: "XTXPTI_1_2",
            P: "PAQFP_0_5_0"
          }
        }
      },
      {
        imagen: "xplor-fuego.jpg",
        titulo: "TOUR XPLOR FUEGO",
        descripcion: [
          "Experimenta una espectacular noche en la selva y emprende esta aventura.",
          "Recorre la selva en vehículos anfibios, desciende en una tirolesa bajo el cielo estrellado o nada por ríos subterráneos. Xplor Fuego te aguarda al ocultarse el sol para subir tu adrenalina. "
        ],
        url_info: "tour-xplor-fuego",
        hybridConf: {
          baseProduct: "XPEFTI_0_12",
          upgrades: {
            T: "XTXPFTI_1_12",
            P: "PAQFP_0_5_0"
          }
        }
      },
      {
        imagen: "xoximilco.jpg",
        titulo: "TOUR XOXIMILCO",
        descripcion: [
          "Celebra una auténtica noche de fiesta mexicana en Xoximilco.",
          "Al ritmo de música en vivo, baila, canta y brinda con tequila mientras haces nuevos amigos. Además, probarás un delicioso menú con lo mejor de la gastronomía de México, incluyendo postres típicos."
        ],
        url_info: "tour-xoximilco",
        hybridConf: {
          baseProduct: "XOEC_0_10",
          upgrades: {
            T: "XTXO_1_10"
          }
        }
      },
      {
        imagen: "xenses.jpg",
        titulo: "TOUR XENSES",
        descripcion: [
          "Despierta a tu niño interior en el Tour Xenses y recorre 15 escenarios de fantasía por tierra, agua y aire, ¡serán un reto para tu mente y tus sentidos! ¿Estás listo para divertirte en este parque fuera de lo usual?"
        ],
        url_info: "tour-xenses",
        hybridConf: {
          baseProduct: "XSEPA_0_15",
          upgrades: {
            T: "XTXSEP_1_15",
            P: "PAQFP_0_5_0"
          }
        }
      },
      {
        imagen: "xenotes.jpg",
        titulo: "TOUR XENOTES OASIS ",
        descripcion: [
          "Conéctate con la naturaleza y disfruta de emocionantes aventuras al conocer los distintos tipos de cenotes.",
          "Desciende en rappel asistido, rema en kayak, refréscate en aguas tranquilas y déjate caer a un cenote natural desde una tirolesa."
        ],
        url_info: "tour-xenotes",
        hybridConf: {
          baseProduct: "XTXEOM_1_11",
          upgrades: {
            P: "PAQFP_0_5_0"
          }
        }
      },
      {
        imagen: "xavage.jpg",
        titulo: "TOUR XAVAGE ALL INCLUSIVE",
        descripcion: [
          "En Xavage podrás elegir y combinar las actividades más extremas de Cancún y Riviera Maya: navegar en kayak, trepar por un circuito de cuerdas, volar por los aires en una tirolesa, sentir la velocidad y los giros de 360° de un jet acuático, conquistar el camino adverso en un vehículo todoterreno y domar la corriente de los únicos ríos rápidos del destino."
        ],
        url_info: "tour-xavage",
        hybridConf: {
          baseProduct: "XVEAI_0_18",
          upgrades: {
            T: "XTXVAI_1_18",
            P: "PAQFP_0_5_0"
          }
        }
      }
    ]
  },
  en: {
    productsTitle: "PARKS AND TOURS",
    productsPromo: "¡Make your experience something unique with our parks!",
    atracciones: [
      {
        imagen: "xcaret.jpg",
        titulo: "XCARET TOUR",
        descripcion: [
          {
            p:
              "Las mejores experiencias para toda la familia en un solo lugar, disfruta de ríos subterráneos, playas y pozas naturales. ¡Ven y vive México en Xcaret!"
          }
        ],
        url_info: "tour-xcaret",
        hybridConf: []
      },
      {
        imagen: "xelha.jpg",
        titulo: "XELHA TOUR",
        descripcion: [
          {
            p:
              "¡Diversión para toda la familia con el tour Xel-Há todo incluido! Sumérgete en un verdadero paraíso de actividades y disfruta de un día inolvidable."
          }
        ],
        url_info: "tour-xelha",
        hybridConf: []
      },
      {
        imagen: "xplor.jpg",
        titulo: "XPLOR TOUR",
        descripcion: [
          {
            p:
              "En Xplor deslizate en las tirolesas más altas de la Riviera Maya, explora en vehículos anfíbios, nada en ríos subterráneos y vive un día de emociones."
          }
        ],
        url_info: "tour-xplor",
        hybridConf: []
      },
      {
        imagen: "xplor-fuego.jpg",
        titulo: "XPLOR FUEGO TOUR",
        descripcion: [
          {
            p:
              "Vive de noche una aventura diferente con Xplor Fuego y atrévete a descubrir la selva como nunca la habías visto."
          }
        ],
        url_info: "tour-xplor-fuego",
        hybridConf: []
      },
      {
        imagen: "xenotes.jpg",
        titulo: "XENOTES OASIS TOUR",
        descripcion: [
          {
            p:
              "Desconéctate del mundo conéctate con la naturaleza, en un día lleno de aventuras emocionantes en un entorno totalmente mágico."
          }
        ],
        url_info: "tour-xenotes",
        tour: true
      },
      {
        imagen: "xoximilco.jpg",
        titulo: "XOXIMILCO TOUR",
        descripcion: [
          {
            p:
              "Celebra al estilo mexicano en Xoximilco. Disfruta del paseo en trajineras, canta con el mariachi, saborea las delicias mexicanas y brinda con tequila."
          }
        ],
        url_info: "tour-xoximilco",
        hybridConf: []
      },
      {
        imagen: "xenses.jpg",
        titulo: "XENSES TOUR",
        descripcion: [
          {
            p:
              "Pon a prueba tus sentidos en Xenses, un nuevo parque fuera de lo usual. Diviértete en más de quince actividades que te llevarán desde el aire hasta el interior de la tierra, con escenarios que retarán tu mente."
          }
        ],
        url_info: "tour-xenses",
        hybridConf: []
      }
    ]
  }
};
