export const XENSES = {
    es: {
        location: 'Ficha Xenses',
        titulo: 'TOUR XENSES',
        imagen: 'slide-xenses.jpg',
        hybridConf: {
            'baseProduct': 'XSEPA_0_15',
            'upgrades': {
                'T': 'XTXSEP_1_15',
                'P': 'PAQF1P_0_5_0'
            }
        },
        info: {
            titleDescripcion: '¡Xenses, el parque donde nada es lo que parece!',
            contentDescripcion: [
                'Pon a prueba tu mente en <strong>Xenses</strong>, un parque de medio día con escenarios fantásticos y actividades que estimularán al máximo tus sentidos.',
                '<h4>Circuito SinSentido</h4>',
                'Aquí iniciarás una travesía por un Pueblo Mexicano donde el equilibrio se pondrá a prueba, para continuar descendiendo por un Tobogán y después volar por los aires como pájaro. Disfruta el Xpá Natural y déjate llevar flotando por el río de sal, sumérgete en el lodo y renueva tu ser con abundantes chorros de agua saliendo por todos lados.',
                '<h4>Circuito ConSentido</h4>',
                'Adéntrate en distintos ecosistemas en completa oscuridad y percibe tu entorno con el resto de tus sentidos. Conoce el Edén e interactúa con la naturaleza, continúa por el Camino de Enanos y Gigantes, pon a prueba tu percepción con divertidas ilusiones ópticas y tomar un descanso mientras bebes una deliciosa limonada en el Jardín Xítrico.',
                '<h4>Escenarios Fantásticos</h4>',
                '50 escenarios fantásticos que desafían tu percepción. Desde que flotas en el interior de una alberca sin que te mojes, te sientas Superman en la fortaleza de la soledad o visites la casa patas pa’rriba, estarás coleccionando momentos fuera de lo común para llevarte contigo.',
                '<strong>Xenses</strong> es donde la suma de tus emociones ¡es más grande que el total de tus sentidos!'
            ]
        },
        includes: {
            titleIncluye: 'Incluye',
            contentIncluye: [
                'Entra a El Rehilete, el punto de partida y donde comienza la magia del parque.',
                'Circuito ConSentido: cruza un divertido Camino de Enanos y Gigantes, recorre en total obscuridad diferentes ecosistemas en Xensatorium, camina por cavernas debajo de la tierra en un Laberinto de Arterias Subterráneas, descubre un espacio lleno de naturaleza en El Edén y refréscate en un Jardín Xítrico.',
                'Circuito SinSentido: escucha y siente un enorme corazón en Latido, mantén el equilibrio en un original Pueblo, deslízate por un Tobogán, planea por los cielos en Vuelo de Pájaro, flota con la corriente en Riolajante y cúbrete de lodo de pies a cabeza en Lodorama.',
                'Pasa momentos increíbles en juegos visuales, ilusiones ópticas y escenarios fantásticos.',
                'Un Obsequiario para adquirir souvenirs muy especiales.',
                'El Xnack para deleitarse con deliciosos bocadillos y recuperar la energía (con costo adicional).',
                'Uso de casillero, sanitarios y regaderas.',
                'Estacionamiento gratuito.',
                'Cajero automático y WiFi.',
            ]
        },
        recommendations: {
            titleRecommendations: 'Recomendaciones',
            contentRecommendations: [
                'Recuerda traer impreso el comprobante de tu compra.',
                'Para tu visita usa traje de baño, zapatos para agua y ropa cómoda.',
                'Trae un cambio de ropa extra y toalla.',
                'Tus bloqueadores, repelentes y bronceadores solo deben contener óxido de titanio y óxido de zinc para su uso en el Parque. En caso de no cumplir con este requisito, a la entrada hay un módulo donde podrás intercambiar tu bloqueador por una muestra libre de químicos; tu bloqueador te será devuelto a la salida.',
                'Recuerda traer efectivo o tarjeta para comprar recuerdos de tu visita o deleitarte con un original bocadillo.',
            ]
        },
        important: {
            titleImportant: 'Información Importante',
            contentImportant: [
                'El parque Xenses abre de lunes a sábado con horarios de admisión que dependen de tu tipo de entrada.',
                        'Sin transportación: Puedes ingresar entre las 8:30 a.m. y 2:00 p.m. La hora de salida dependerá de la hora de llegada al Parque (la duración aproximada del recorrido es de cinco horas).',
                        'Con transportación: Por la MAÑANA: entrada de 8:30 a.m. a 10:00 a.m. / Salida entre 2:00 p.m. y 3:00 p.m. Por la TARDE: entrada de 1:00 p.m. a 2:00 p.m. / Salida entre 6:00 p.m. y 7:00 p.m.',
                'Dura aproximadamente 5 h.',
                'Los visitantes con alguna discapacidad deben ir siempre acompañados por un familiar o amigo que los asista en todo momento.',
                'Actividades no recomendadas para personas con problemas cardiacos, miedo a las alturas, claustrofobia o que se mareen fácilmente, que tengan una cirugía reciente, dolor de espalda o con un peso superior a 136 kg, ni para mujeres embarazadas. Para las Tirolesas la altura mínima requerida es de 1.40m. y el peso mínimo requerido es de 40 kg.',
                'La edad mínima recomendada es de 5 años.',
                'Niños de 5 a 11 años tienen 25% de descuento sobre el precio de adulto. Es indispensable que presenten identificación oficial en las taquillas del Parque. En caso de no hacerlo, se aplicará la tarifa de menor a aquellos que midan entre 1 m y 1.40 de estatura.',
                'Las actividades de Tobogán y Vuelo de Pájaro incluyen áreas acuáticas con una profundidad de entre 1.10 m y 1.30 m de profundidad. Se recomienda que en estas actividades los niños menores de 1.30 m de estatura que no sepan nadar, vayan acompañados de sus padres.',
                'La actividad de Vuelo de Pájaro es recomendada para niños a partir de los cinco años, siempre y cuando el arnés les ajuste correctamente.',
                'Las reservaciones canceladas con más de 2 días de antelación a la fecha de llegada están sujetas a un cargo del 10% por gastos administrativos; y con menos de 2 días antes de la fecha de llegada, o en caso de no llegar, no serán reembolsables.',
                'No es posible hacer cambio de fecha el mismo día de tu visita.',
            ]
        }
    },
    en: {
        location: 'Xenses',
        titulo: 'XENSES TOUR',
        imagen: 'slide-xenses.jpg',
        info: {
            titleDescripcion: 'Fun, nature and culture for all the family in Xcaret Park',
            contentDescripcion: [
                'Xcaret its a natural park with more than 50 attractions for all ages, perfect to go with all the family.',
                'Here you can swim in underground rivers, pasear por senderos en medio de la jungla y recorrer cuevas misteriosas. Descubre nuevos colores en el Mariposario y los misterios del fondo marino en el Acuario de Arrecife de Coral. Admira de cerca venados, jaguares, flamingos y aves exóticas.',
                'En Xcaret México hay diversos atractivos para visitar, como los vestigios precolombinos, el Museo de Arte Popular Mexicano, una clásica hacienda henequenera y la capilla de Guadalupe.',
                'Además disfruta de la cultura mexicana con las danzas prehispánicas, la charrería y la magnífica presentación de Xcaret México Espectacular, con más de 300 artistas en escena.',
                'En Xcaret Riviera Maya hay una amplia variedad de restaurantes para elegir. Y para tu mayor comodidad, tenemos áreas de descanso, vestidores, duchas y sanitarios, ubicados en distintas áreas del parque.',
                '¡Ven a Xcaret y vive con tu familia la experiencia más inolvidable de sus vacaciones!',
            ]
        },
        includes: {
            titleIncluye: 'Includes',
            contentIncluye: [
                'Access to the beach, inlet and natural pools. ',
                'Actividades acuáticas en tres ríos subterráneos (incluye bolsa de seguridad para el recorrido).',
                'Espectáculos y exhibiciones (Charrería, Voladores de Papantla y Danzas Prehispánicas).',
                'La nueva presentación de “Xcaret México Espectacular”.',
                'Visitas al Acuario de Arrecife Coral, el Mariposario, el Aviario y la Isla de los Jaguares.',
                'Acceso a la taquilla especial por comprar en línea.',
                'Chalecos salvavidas y llantas flotantes.',
                'Camastros, hamacas y áreas de descanso.',
                'Sanitarios, vestidores y regaderas.',
                'Estacionamiento gratuito.',
                'Otros servicios (WiFi, cajeros automáticos y tiendas).',
                'Tu Tour incluye transportación solo si la agregas al momento de tu compra.',
            ]
        },
        recommendations: {
            titleRecommendations: 'Recommendations',
            contentRecommendations: [
                '¡All the photos of your experience at an special price! By including your photo pass before your visit you get an exclusive price.',
                'Recuerda traer impreso el comprobante de compra de tu admisión al Parque.',
                'Usa ropa cómoda de playa y traje de baño, trae una muda extra.',
                'Si el tour es con transportación, confirma la hora y lugar de salida .',
                '¡Ayúdanos a cuidar y preservar nuestro ecosistema! Para no afectar el balance natural del ecosistema, en el Parque solo está permitido el uso de bloqueadores y bronceadores que contengan óxido de titanio y óxido de zinc. En caso de no cumplir con este requisito, a la entrada del Parque encontrarás un módulo de información donde podrás intercambiar tu bloqueador por una muestra libre de químicos; tu bloqueador te será devuelto a la salida.',
                'Lleva efectivo o tarjeta para comprar souvenirs, realizar alguna actividad opcional o adquirir las fotos de tu visita.',
            ]
        },
        important: {
            titleImportant: 'Important Information',
            contentImportant: [
                'Xcaret Park opens 365 days a year from 8:30 a.m. to 9:30 p.m.',
                'Niños menores de 4 años entran gratis.',
                'Niños de 5 a 11 años tienen 25% de descuento sobre el precio de adulto (indispensable presentar identificación en las taquillas del Parque).',
                'Niños mayores a 1.40 m pero menores de 12 años, deberán presentar identificación para comprobar su edad.',
                'Las reservaciones canceladas con más de 2 días de antelación a la fecha de llegada, están sujetas a un cargo del 10% por gastos administrativos; de 2 a 0 días antes de la fecha de llegada, o en caso de no llegar, no serán reembolsables.',
                'No es posible hacer el cambio de fecha el mismo día de tu visita.',
                'La renta del equipo de snorkel tiene un costo de $10 USD (se requiere de un depósito reembolsable de $ 25 USD).',
                'Para los visitantes con admisión Xcaret Plus (con alimentos) el uso del equipo de snorkel está incluido (requiere un depósito reembolsable de $ 10 USD).',
            ]
        }
    }
};
