import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Inject	} from '@angular/core';

/**
 * APM RUM
 */
import { ApmService } from '@elastic/apm-rum-angular';

/**
 * Rutas
 */
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app.routes';

/**
 * Servicios
 */
import { ScreenDetectorService } from './services/screenDetector/screenDetector.service';
import { FichasService } from './services/fichas/fichas.service';

import { AppComponent } from './app.component';

/**
 * Shared Components
 */
import { HeaderComponent } from './components/shared/header/header.component';
import { FooterComponent } from './components/shared/footer/footer.component';

/**
 * Paginas
 */
import { HomeComponent } from './components/pages/home/home.component';
import { FichaComponent } from './components/pages/ficha/ficha.component';
import { PageNotFoundComponent } from './components/pages/404/404.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    FichaComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [
    ApmService,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    ScreenDetectorService,
    FichasService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(@Inject(ApmService) service: ApmService) {
    const apm = service.init({
      serviceName: 'GX-FAILOVER',
      serverUrl: 'https://72be08cc667f4a6390b56014bb4f905e.apm.us-east-1.aws.cloud.es.io:443'
    })
  }
 }
