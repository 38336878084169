import { Injectable } from "@angular/core";
import { XCARET } from "../../texts/fichas/xcaret/index";
import { XELHA } from "../../texts/fichas/xelha/index";
import { XPLOR } from "../../texts/fichas/xplor/index";
import { XPLOR_FUEGO } from "../../texts/fichas/xplor-fuego/index";
import { XOXIMILCO } from "../../texts/fichas/xoximilco/index";
import { XENSES } from "../../texts/fichas/xenses/index";
import { XENOTES } from "../../texts/fichas/xenotes/index";
import { XAVAGE } from "../../texts/fichas/xavage/index";
// import { xavage } from '../../texts/fichas/xavage';

@Injectable()
export class FichasService {
  constructor() {}

  getParque(park) {
    let data;
    switch (park) {
      case "tour-xcaret":
        data = XCARET.es;
        break;
      case "tour-xelha":
        data = XELHA.es;
        break;
      case "tour-xplor":
        data = XPLOR.es;
        break;
      case "tour-xplor-fuego":
        data = XPLOR_FUEGO.es;
        break;
      case "tour-xoximilco":
        data = XOXIMILCO.es;
        break;
      case "tour-xenses":
        data = XENSES.es;
        break;
      case "tour-xenotes":
        data = XENOTES.es;
        break;
      case "tour-xavage":
        data = XAVAGE.es;
        break;
      default:
        data = XCARET.es;
        break;
    }
    return data;
  }
}
