import { Component, OnInit } from '@angular/core';
import { ScreenDetectorService } from '../../../services/screenDetector/screenDetector.service';

@Component({
    selector: 'app-header-comp',
    templateUrl: './header.component.html',
    styleUrls: [ './header.component.styl' ]
})
export class HeaderComponent implements OnInit {

    constructor(public scnSz: ScreenDetectorService) {
    }

    ngOnInit() {
    }
}
