import { Component, OnInit } from "@angular/core";
import { ScreenDetectorService } from "../../../services/screenDetector/screenDetector.service";

import hybrid from "../../shared/hybrid";

/**
 * Textos
 */
// import { uiText } from '../../texts/ui';
import { HOME_TEXTS } from "../../../texts/home/index";

@Component({
  selector: "app-home-comp",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.styl"]
})
export class HomeComponent implements OnInit {
  lang = "es";
  content: any = [];
  atracciones = [];

  constructor(private scnSz: ScreenDetectorService) {
    if (this.lang === "es") {
      this.content = HOME_TEXTS.es;
    } else {
      this.content = HOME_TEXTS.en;
    }
    this.atracciones = this.content.atracciones;
    let index = 0;
    this.atracciones.forEach(element => {
      // if (element.hybridConf) {
      if (element.hybridConf.upgrades) {
        let upgrades = "";
        let upgradeIndex = 0;
        for (let key in element.hybridConf.upgrades) {
          if (element.hybridConf.upgrades.hasOwnProperty(key)) {
            let len = Object.keys(element.hybridConf.upgrades).length;
            let upgrade = element.hybridConf.upgrades[key];
            upgrades += key + ":" + upgrade;
            if (upgradeIndex < len - 1) {
              upgrades += ",";
            }
            upgradeIndex = upgradeIndex + 1;
          }
        }
        element.hybridConf.upgradesText = upgrades;
      }
      // } else if (element.titulo === 'TOUR XAVAGE') {
      //     let upgrades = '';
      //     let upgradeIndex = 0;
      // for (let item of element.hybridConf) {
      //     console.log("Hello");
      //     for (let key of item.upgrades) {
      //         if (item.upgrades.hasOwnProperty(key)) {
      //             let len = Object.keys(item.upgrades).length;
      //             let upgrade = item.upgrades[key];
      //             upgrades += key + ':' + upgrade;
      //             if (upgradeIndex < len - 1) {
      //                 upgrades += ',';
      //             }
      //             upgradeIndex = upgradeIndex + 1;
      //         }
      //     }
      //     console.log(item);
      // }
      // element.hybridConf.upgradesText = upgrades;
      // }
      index = index + 1;
    });
  }

  ngOnInit(): void {
    hybrid();
  }
}
