const hybrid = (): void => {
  const hybNode = document.getElementById("route-container");

  (function(d, t) {
    // Elimina los flatpickrs que crea hybrid flotante en el body
    const elements = document.getElementsByClassName("flatpickr-calendar");
    while (elements.length > 0) elements[0].remove();

    //Crear script de hybrid
    let s = document.createElement(t);
    // @ts-ignore
    s.src = "https://www.bexcaret.com/cart/DJ53zY/builds/hybrid.min.js";
    //Borra los scripts al cambio de pagina angular
    d.innerHTML = "";
    d.append(s);
    // @ts-ignore
    s.onload = s.onreadystatechange = function() {
      let rs = this.readyState;
      if (rs) {
        if (rs !== "complete") {
          if (rs !== "loaded") {
            return;
          }
        }
      }
    };
    let src = document.getElementsByTagName(t)[0],
      par = src.parentNode;
    par.insertBefore(s, src);
  })(hybNode, "script");
};

export default hybrid;
