export const XPLOR = {
    es: {
        location: 'Ficha Xplor',
        titulo: 'TOUR XPLOR',
        imagen: 'slide-xplor.jpg',
        hybridConf: {
            'baseProduct': 'XPETI_0_2',
            'upgrades': {
                'T': 'XTXPTI_1_2',
                'P': 'PAQF1P_0_5_0'
            }
        },
        info: {
            titleDescripcion: 'Descubre en Xplor una aventura por las alturas, la superficie y el interior de la Tierra',
            contentDescripcion: [
                'Libera tu adrenalina y lánzate a una divertida aventura en <strong>Xplor</strong>. En este parque en el corazón de la selva en la <strong>Riviera Maya</strong> realizarás cinco distintas actividades con el equipo más moderno y seguro.',
                'Deslízate por dos circuitos de tirolesas, explora cavernas o nada por un Río de Estalactitas. También podrás pasear por un río subterráneo a bordo de una balsa personal o recorrer senderos por la selva a bordo de un vehículo anfibio.',
                'Para recuperar tus energías, disfruta de deliciosas bebidas como licuados naturales o aguas frescas. Cierra con broche de oro tu día en <strong>Xplor</strong> con un delicioso buffet con comida saludable y opciones para todos los gustos.'
            ]
        },
        includes: {
            titleIncluye: 'Incluye',
            contentIncluye: [
                'Acceso a la taquilla preferencial por comprar en línea.',
                'Dos circuitos de 14 Tirolesas. ',
                'Recorrido en un Vehículo Anfibio en dos circuitos, de 5 km cada uno, por la selva.',
                'Dos circuitos de ríos subterráneos remando en Balsas.',
                'Actividad de nado por 400 m en el Río de Estalactitas.',
                'Hamacuatizaje.',
                'Caminata por Cavernas.',
                'Snacks y bebidas ilimitadas (galletas de avena, frutas, licuados y aguas frescas).',
                'Comida buffet.',
                'Casillero para dos personas.',
                'Áreas de descanso, vestidores y baños.',
                'Otros servicios (WiFi, cajeros automáticos y tiendas con costo adicional).',
                'Estacionamiento gratuito.',
            ]
        },
        recommendations: {
            titleRecommendations: 'Recomendaciones',
            contentRecommendations: [
                'Recuerda traer impreso el comprobante de tu compra.',
                'La edad mínima requerida para el parque Xplor es de 5 años y los niños deberán estar acompañados por un adulto en todo momento. NOTA: para la actividad de Tirolesas se requiere una altura mínima de 1.10 m y un peso mínimo de 40 kg.',
                'Si el Tour es con transportación, confirma la hora y el lugar de salida.',
                'Usa traje de baño, zapatos para agua y repelente de insectos; trae una muda de ropa extra y toalla.',
                'Tus bloqueadores, repelentes y bronceadores solo deben contener óxido de titanio y óxido de zinc para su uso en el Parque. En caso de no cumplir con este requisito, a la entrada hay un módulo donde podrás intercambiarlos por una muestra libre de químicos; tus productos te serán devueltos a la salida.',
                'Lleva efectivo o tarjeta para comprar souvenirs o adquirir las fotos de tu visita.',
            ]
        },
        important: {
            titleImportant: 'Información Importante',
            contentImportant: [
                'Xplor abre de lunes a sábado de 9:00 a.m. a 5:00 p.m.',
                'La edad mínima para ingresar a Xplor es de 5 años. Los niños entre 5 y 11 años obtienen un 25% de descuento sobre el precio de adulto. (indispensable presentar identificación en las taquillas del Parque).',
                'Niños mayores a 1.40 m, pero menores de 12 años, deberán presentar identificación para comprobar su edad.',
                'Algunas actividades tienen limitantes para futuras mamás.',
                'Si tienes problemas cardíacos, miedo a las alturas, una cirugía reciente, dolor de espalda o te mareas fácilmente ciertas actividades del Parque no son recomendables.',
                'Para realizar la actividad de Tirolesas el peso máximo es de 136 kg y el mínimo de 40 kg. La capacidad en circunferencia de los arneses alrededor de la cintura es de 130 cm y de 70 cm alrededor de cada pierna. En el caso de los niños, el arnés deberá ajustarse correctamente, de lo contrario, por su seguridad, no podrán realizar la actividad.',
                'El Tour incluye transportación solo si la agregas al momento de tu compra. Deberás confirmar hora y lugar de salida.',
                'Las reservaciones canceladas con más de 2 días de antelación a la fecha de llegada, están sujetas a un cargo del 10% por gastos administrativos; de 2 a 0 días antes de la fecha de llegada, o en caso de no llegar, no serán reembolsables.',
                'No es posible hacer el cambio de fecha el mismo día de tu visita.',
            ]
        }
    },
    en: {
        location: 'Xplor',
        titulo: 'XPLOR TOUR',
        imagen: 'slide-xplor.jpg',
        info: {
            titleDescripcion: 'Fun, nature and culture for all the family in Xcaret Park',
            contentDescripcion: [
                'Xcaret its a natural park with more than 50 attractions for all ages, perfect to go with all the family.',
                'Here you can swim in underground rivers, pasear por senderos en medio de la jungla y recorrer cuevas misteriosas. Descubre nuevos colores en el Mariposario y los misterios del fondo marino en el Acuario de Arrecife de Coral. Admira de cerca venados, jaguares, flamingos y aves exóticas.',
                'En Xcaret México hay diversos atractivos para visitar, como los vestigios precolombinos, el Museo de Arte Popular Mexicano, una clásica hacienda henequenera y la capilla de Guadalupe.',
                'Además disfruta de la cultura mexicana con las danzas prehispánicas, la charrería y la magnífica presentación de Xcaret México Espectacular, con más de 300 artistas en escena.',
                'En Xcaret Riviera Maya hay una amplia variedad de restaurantes para elegir. Y para tu mayor comodidad, tenemos áreas de descanso, vestidores, duchas y sanitarios, ubicados en distintas áreas del parque.',
                '¡Ven a Xcaret y vive con tu familia la experiencia más inolvidable de sus vacaciones!',
            ]
        },
        includes: {
            titleIncluye: 'Includes',
            contentIncluye: [
                'Access to the beach, inlet and natural pools. ',
                'Actividades acuáticas en tres ríos subterráneos (incluye bolsa de seguridad para el recorrido).',
                'Espectáculos y exhibiciones (Charrería, Voladores de Papantla y Danzas Prehispánicas).',
                'La nueva presentación de “Xcaret México Espectacular”.',
                'Visitas al Acuario de Arrecife Coral, el Mariposario, el Aviario y la Isla de los Jaguares.',
                'Acceso a la taquilla especial por comprar en línea.',
                'Chalecos salvavidas y llantas flotantes.',
                'Camastros, hamacas y áreas de descanso.',
                'Sanitarios, vestidores y regaderas.',
                'Estacionamiento gratuito.',
                'Otros servicios (WiFi, cajeros automáticos y tiendas).',
                'Tu Tour incluye transportación solo si la agregas al momento de tu compra.',
            ]
        },
        recommendations: {
            titleRecommendations: 'Recommendations',
            contentRecommendations: [
                '¡All the photos of your experience at an special price! By including your photo pass before your visit you get an exclusive price.',
                'Recuerda traer impreso el comprobante de compra de tu admisión al Parque.',
                'Usa ropa cómoda de playa y traje de baño, trae una muda extra.',
                'Si el tour es con transportación, confirma la hora y lugar de salida .',
                '¡Ayúdanos a cuidar y preservar nuestro ecosistema! Para no afectar el balance natural del ecosistema, en el Parque solo está permitido el uso de bloqueadores y bronceadores que contengan óxido de titanio y óxido de zinc. En caso de no cumplir con este requisito, a la entrada del Parque encontrarás un módulo de información donde podrás intercambiar tu bloqueador por una muestra libre de químicos; tu bloqueador te será devuelto a la salida.',
                'Lleva efectivo o tarjeta para comprar souvenirs, realizar alguna actividad opcional o adquirir las fotos de tu visita.',
            ]
        },
        important: {
            titleImportant: 'Important Information',
            contentImportant: [
                'Xcaret Park opens 365 days a year from 8:30 a.m. to 9:30 p.m.',
                'Niños menores de 4 años entran gratis.',
                'Niños de 5 a 11 años tienen 25% de descuento sobre el precio de adulto (indispensable presentar identificación en las taquillas del Parque).',
                'Niños mayores a 1.40 m pero menores de 12 años, deberán presentar identificación para comprobar su edad.',
                'Las reservaciones canceladas con más de 2 días de antelación a la fecha de llegada, están sujetas a un cargo del 10% por gastos administrativos; de 2 a 0 días antes de la fecha de llegada, o en caso de no llegar, no serán reembolsables.',
                'No es posible hacer el cambio de fecha el mismo día de tu visita.',
                'La renta del equipo de snorkel tiene un costo de $10 USD (se requiere de un depósito reembolsable de $ 25 USD).',
                'Para los visitantes con admisión Xcaret Plus (con alimentos) el uso del equipo de snorkel está incluido (requiere un depósito reembolsable de $ 10 USD).',
            ]
        }
    }
};
