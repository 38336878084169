import { NgModule } from "@angular/core";
import { RouterModule, Routes } from '@angular/router';
/**
 * Importar componentes
 */
import { HomeComponent } from './components/pages/home/home.component';
import { FichaComponent } from './components/pages/ficha/ficha.component';
import { PageNotFoundComponent } from './components/pages/404/404.component';

const ROUTES: Routes = [
    { path: 'ficha/:prod', component: FichaComponent },
    { path: 'home', component: HomeComponent },
    { path: '**', pathMatch: 'full', redirectTo: '/home' },

    // { path: 'path/:routeParam', component: MyComponent },
    // { path: 'staticPath', component: ... },
    // { path: '**', component: ... },
    // { path: 'oldPath', redirectTo: '/staticPath' },
    // { path: ..., component: ..., data: { message: 'Custom' }
];

@NgModule({
    imports: [RouterModule.forRoot(ROUTES)],
    exports: [RouterModule]
  })
export class AppRoutingModule {}