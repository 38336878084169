export const XELHA = {
    es: {
        location: 'Ficha Xel-Há',
        titulo: 'TOUR XEL-HÁ',
        imagen: 'slide-xelha.jpg',
        hybridConf: {
            'baseProduct': 'XHETI_0_3',
            'upgrades': {
                'T': 'XTXHTI_1_3',
                'P': 'PAQF1P_0_5_0'
            }
        },
        info: {
            titleDescripcion: 'Xel-Há Todo Incluido, el paraíso del snorkel',
            contentDescripcion: [
                'Adéntrate a <strong>Xel-Há México</strong>, una maravilla natural repleta de caletas, cenotes y lagunas. Este espectacular parque en la <strong>Riviera Maya</strong> es una excelente opción para disfrutarlo en familia.',
                'Sumérgete en sus refrescantes aguas para hacer snorkel y observar un asombroso ecosistema marino con peces multicolores. Durante tu visita podrás deslizarte en tirolesas, dejarte llevar por el cauce del río mientras descansas en una llanta, pasear en bicicleta por senderos selváticos o arrojarte al agua desde la Piedra del Valor.',
                'En <strong>Xel-Há Todo Incluido</strong> inicias el día con un desayuno continental ligero para empezar la aventura con buen ánimo. Ya que necesitarás recobrar energías, te espera un delicioso buffet y refrescantes bebidas ilimitadas. ¡En el </strong>Tour Xel-Há</strong> sólo pensarás en divertirte!'
            ]
        },
        includes: {
            titleIncluye: 'Incluye',
            contentIncluye: [
                'Desayuno Continental de 9:30 a.m. a 11:30 a.m.',
                'Comida buffet a partir de las 11:30 a.m. hasta las 5:30 p.m.',
                'Bebidas sin alcohol ilimitadas y barra libre nacional.',
                'Snorkel ilimitado en el río y la caleta.',
                'Faro-Mirador con vista a 40 m de altura y descenso en tobogán.',
                'Paseos por jardines y senderos de la selva.',
                'Entrada al Meliponario y Vivero.',
                'Acceso a El Mundo de los Niños.',
                'Áreas de descanso con hamacas y camastros.',
                'Chalecos salvavidas, llantas flotantes y bicicletas.',
                'Uso del equipo de snorkel con tubo de regalo.',
                'Uso de casilleros, vestidores y una toalla.',
                'Sanitarios y regaderas.',
                'Estacionamiento gratuito.',
                'Otros servicios (WiFi, cajeros automáticos y tiendas con costo adicional).',
            ]
        },
        recommendations: {
            titleRecommendations: 'Recomendaciones',
            contentRecommendations: [
                'Debes traer impreso tu comprobante de compra.',
                'Usa ropa cómoda de playa, traje de baño. Trae una muda extra.',
                'Tus bloqueadores y bronceadores solo deben contener óxido de titanio y óxido de zinc para su uso en el Parque. En caso de no cumplir con este requisito, a la entrada hay un módulo donde podrás intercambiar tu bloqueador por una muestra libre de químicos; tu bloqueador te será devuelto a la salida.',
                'Lleva efectivo o tarjeta para comprar souvenirs, realizar alguna Actividad Opcional o adquirir las fotos de tu visita.',
            ]
        },
        important: {
            titleImportant: 'Información Importante',
            contentImportant: [
                'El parque Xel-Há abre todos los días del año de 8:30 a.m. a 6:00 p.m.',
                'Niños menores de 4 años entran gratis. De 5 a 11 años tienen 25% de descuento sobre el precio de adulto (en ambos casos es indispensable presentar identificación en las taquillas de los Parques).',
                'Niños mayores a 1.40 m pero menores de 12 años, también deberán presentar identificación para comprobar su edad.',
                'La transportación desde Cancún, Playa del Carmen y la Riviera Maya estará incluida solo si la agregas al momento de tu compra. Deberás confirmar hora y lugar de salida.',
                'Las reservaciones canceladas con más de dos días de antelación a la fecha de llegada, están sujetas a un cargo del 10% por gastos administrativos. De 2 a 0 días antes de la fecha de llegada, o en caso de no llegar, no serán reembolsables.',
                'No se permiten cambios de fecha el mismo día de tu visita.',
            ]
        }
    },
    en: {
        location: 'Xel-Há',
        titulo: 'XEL-HÁ TOUR',
        imagen: 'slide-xelha.jpg',
        info: {
            titleDescripcion: 'Fun, nature and culture for all the family in Xcaret Park',
            contentDescripcion: [
                'Xcaret its a natural park with more than 50 attractions for all ages, perfect to go with all the family.',
                'Here you can swim in underground rivers, pasear por senderos en medio de la jungla y recorrer cuevas misteriosas. Descubre nuevos colores en el Mariposario y los misterios del fondo marino en el Acuario de Arrecife de Coral. Admira de cerca venados, jaguares, flamingos y aves exóticas.',
                'En Xcaret México hay diversos atractivos para visitar, como los vestigios precolombinos, el Museo de Arte Popular Mexicano, una clásica hacienda henequenera y la capilla de Guadalupe.',
                'Además disfruta de la cultura mexicana con las danzas prehispánicas, la charrería y la magnífica presentación de Xcaret México Espectacular, con más de 300 artistas en escena.',
                'En Xcaret Riviera Maya hay una amplia variedad de restaurantes para elegir. Y para tu mayor comodidad, tenemos áreas de descanso, vestidores, duchas y sanitarios, ubicados en distintas áreas del parque.',
                '¡Ven a Xcaret y vive con tu familia la experiencia más inolvidable de sus vacaciones!'
            ]
        },
        includes: {
            titleIncluye: 'Includes',
            contentIncluye: [
                'Access to the beach, inlet and natural pools. ',
                'Actividades acuáticas en tres ríos subterráneos (incluye bolsa de seguridad para el recorrido).',
                'Espectáculos y exhibiciones (Charrería, Voladores de Papantla y Danzas Prehispánicas).',
                'La nueva presentación de “Xcaret México Espectacular”.',
                'Visitas al Acuario de Arrecife Coral, el Mariposario, el Aviario y la Isla de los Jaguares.',
                'Acceso a la taquilla especial por comprar en línea.',
                'Chalecos salvavidas y llantas flotantes.',
                'Camastros, hamacas y áreas de descanso.',
                'Sanitarios, vestidores y regaderas.',
                'Estacionamiento gratuito.',
                'Otros servicios (WiFi, cajeros automáticos y tiendas).',
                'Tu Tour incluye transportación solo si la agregas al momento de tu compra.',
            ]
        },
        recommendations: {
            titleRecommendations: 'Recommendations',
            contentRecommendations: [
                '¡All the photos of your experience at an special price! By including your photo pass before your visit you get an exclusive price.',
                'Recuerda traer impreso el comprobante de compra de tu admisión al Parque.',
                'Usa ropa cómoda de playa y traje de baño, trae una muda extra.',
                'Si el tour es con transportación, confirma la hora y lugar de salida .',
                '¡Ayúdanos a cuidar y preservar nuestro ecosistema! Para no afectar el balance natural del ecosistema, en el Parque solo está permitido el uso de bloqueadores y bronceadores que contengan óxido de titanio y óxido de zinc. En caso de no cumplir con este requisito, a la entrada del Parque encontrarás un módulo de información donde podrás intercambiar tu bloqueador por una muestra libre de químicos; tu bloqueador te será devuelto a la salida.',
                'Lleva efectivo o tarjeta para comprar souvenirs, realizar alguna actividad opcional o adquirir las fotos de tu visita.',
            ]
        },
        important: {
            titleImportant: 'Important Information',
            contentImportant: [
                'Xcaret Park opens 365 days a year from 8:30 a.m. to 9:30 p.m.',
                'Niños menores de 4 años entran gratis.',
                'Niños de 5 a 11 años tienen 25% de descuento sobre el precio de adulto (indispensable presentar identificación en las taquillas del Parque).',
                'Niños mayores a 1.40 m pero menores de 12 años, deberán presentar identificación para comprobar su edad.',
                'Las reservaciones canceladas con más de 2 días de antelación a la fecha de llegada, están sujetas a un cargo del 10% por gastos administrativos; de 2 a 0 días antes de la fecha de llegada, o en caso de no llegar, no serán reembolsables.',
                'No es posible hacer el cambio de fecha el mismo día de tu visita.',
                'La renta del equipo de snorkel tiene un costo de $10 USD (se requiere de un depósito reembolsable de $ 25 USD).',
                'Para los visitantes con admisión Xcaret Plus (con alimentos) el uso del equipo de snorkel está incluido (requiere un depósito reembolsable de $ 10 USD).',
            ]
        }
    }
};
