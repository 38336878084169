import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FichasService } from './../../../services/fichas/fichas.service';
import { ScreenDetectorService } from '../../../services/screenDetector/screenDetector.service';

import hybrid from '../../shared/hybrid';

@Component({
  selector: 'app-ficha-component',
  templateUrl: './ficha.component.html',
  styleUrls: ['./ficha.component.styl']
})
export class FichaComponent implements OnInit {
  data: any;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _fichaService: FichasService,
    public scnSz: ScreenDetectorService
  ) {
    this._activatedRoute.params.subscribe(params => {
      this.data = this._fichaService.getParque(params.prod);
      if (this.data.hybridConf.upgrades) {
        let upgrades = '';
        let upgradeIndex = 0;
        for (let key in this.data.hybridConf.upgrades) {
          if (this.data.hybridConf.upgrades.hasOwnProperty(key)) {
            let len = Object.keys(this.data.hybridConf.upgrades).length;
            let upgrade = this.data.hybridConf.upgrades[key];
            upgrades += key + ':' + upgrade;
            if (upgradeIndex < len - 1) {
              upgrades += ',';
            }
            upgradeIndex = upgradeIndex + 1;
          }
        }
        this.data.hybridConf.upgradesText = upgrades;
      }
    });
  }

  ngOnInit(): void {
    hybrid();
  }

  changeTab(tab) {
    let tabsBtns = document.getElementsByClassName('tab-button');
    let tabs = document.getElementsByClassName('card');
    for (let i = 0; i < tabs.length; i++) {
      tabs[i].classList.remove('active');
      tabsBtns[i].classList.remove('active');
      if (tabs[i].id === tab) {
        tabs[i].classList.add('active');
        tabsBtns[i].classList.add('active');
      }
    }
  }
}
