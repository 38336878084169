export const XOXIMILCO = {
    es: {
        location: 'Ficha Xoximilco',
        titulo: 'TOUR XOXIMILCO',
        imagen: 'slide-xoximilco.jpg',
        hybridConf: {
            'baseProduct': 'XOEC_0_10',
            'upgrades': {
                'T': 'XTXO_1_10'
            }
        },
        info: {
            titleDescripcion: '¡Estás invitado a una fiesta mexicana en Xoximilco!',
            contentDescripcion: [
            '¡Únete a la fiesta! Aborda una trajinera en el <strong>Parque Xoximilco</strong> y navega plácidamente por canales mientras cantas, bailas y haces nuevos amigos en esta tradición del México de la época de oro.',
            'Durante la travesía se podrá disfrutar de barra libre de tequila, cerveza, refrescos y aguas frescas, además de probar platillos típicos mexicanas en una experiencia culinaria memorable. Todo al ritmo de música en vivo que va desde los ritmos norteños y marimba, hasta ranchera y el inmortal Mariachi. Además, en <strong>Xoximilco</strong> los martes y viernes son noches temáticas que te remitirán a la década de los noventa, mientras los jueves se realiza un homenaje muy especial a Juan Gabriel.',
            '¡En <strong>Xoximilco Cancún</strong> la diversión está asegurada para tener una noche de fiesta inolvidable!',
            '<strong>Nota importante: Cada trajinera tiene una capacidad de 20 personas.</strong>'
            ]
        },
        includes: {
            titleIncluye: 'Incluye',
            contentIncluye: [
                'Bienvenida en una kermés mexicana con juegos típicos, esquites y aguas frescas.',
                'Recorrido nocturno en trajinera a través de canales.',
                'Degustación de platillos típicos mexicanos muy representativos de México.',
                'Barra libre de tequila, cerveza, aguas frescas y refrescos.',
                'Grupos de música típica mexicana en vivo: mariachi, norteño, ranchero, jarana, marimba, banda de pueblo y campiranos.',
                'Los martes y viernes, una fiesta con el ambiente y la esencia de los noventa en Xoximilco Retro.',
                'Los jueves, un homenaje musical al famoso cantautor mexicano Juan Gabriel.',
                'Un divertido animador que te acompañará durante el recorrido poniendo el ambiente con juegos, información de los platillos y una actitud festiva que garantiza tu diversión.',
                'Duración aproximada: 3 horas de recorrido.',
                'Estacionamiento gratuito.',
                'Otros servicios (cajero automático y tienda).',
            ]
        },
        recommendations: {
            titleRecommendations: 'Recomendaciones',
            contentRecommendations: [
                'Debes reservar con anticipación y traer impreso el comprobante de compra.',
                'Usa ropa y zapatos cómodos, preferentemente pantalón largo.',
                'Debes usar repelente de insectos biodegradable.',
                'Lleva efectivo o tarjeta para comprar souvenirs o adquirir las fotos de tu visita.',
            ]
        },
        important: {
            titleImportant: 'Información Importante',
            contentImportant: [
                'El horario de embarque de la primera trajinera es a las 8:00 p.m. de septiembre a marzo y a las 8:30 p.m. de abril a agosto.',
                'Duración: 3 h aproximadamente.',
                'La edad mínima para ingresar a Xoximilco es de 5 años. Los niños entre 5 y 11 años obtienen un 25% de descuento sobre el precio de adulto. (indispensable presentar identificación en las taquillas del Parque).',
                'Niños mayores a 1.40 m pero menores de 12 años, deberán presentar identificación para comprobar su edad.',
                'La transportación estará incluida solo si la agregas al momento de tu compra. Deberás confirmar hora y lugar de salida.',
                'Las reservaciones canceladas con más de 2 días de antelación a la fecha de llegada, están sujetas a un cargo del 10% por gastos administrativos; de 2 a 0 días antes de la fecha de llegada, o en caso de no llegar, no serán reembolsables.',
                'No es posible hacer el cambio de fecha el mismo día de tu visita.',
                'Cada trajinera tiene capacidad máxima de 20 personas.',
            ]
        }
    },
    en: {
        location: 'Xoximilco',
        titulo: 'XOXIMILCO TOUR',
        imagen: 'slide-xoximilco.jpg',
        info: {
            titleDescripcion: 'Fun, nature and culture for all the family in Xcaret Park',
            contentDescripcion: [
                'Xcaret its a natural park with more than 50 attractions for all ages, perfect to go with all the family.',
                'Here you can swim in underground rivers, pasear por senderos en medio de la jungla y recorrer cuevas misteriosas. Descubre nuevos colores en el Mariposario y los misterios del fondo marino en el Acuario de Arrecife de Coral. Admira de cerca venados, jaguares, flamingos y aves exóticas.',
                'En Xcaret México hay diversos atractivos para visitar, como los vestigios precolombinos, el Museo de Arte Popular Mexicano, una clásica hacienda henequenera y la capilla de Guadalupe.',
                'Además disfruta de la cultura mexicana con las danzas prehispánicas, la charrería y la magnífica presentación de Xcaret México Espectacular, con más de 300 artistas en escena.',
                'En Xcaret Riviera Maya hay una amplia variedad de restaurantes para elegir. Y para tu mayor comodidad, tenemos áreas de descanso, vestidores, duchas y sanitarios, ubicados en distintas áreas del parque.',
                '¡Ven a Xcaret y vive con tu familia la experiencia más inolvidable de sus vacaciones!',
            ]
        },
        includes: {
            titleIncluye: 'Includes',
            contentIncluye: [
                'Access to the beach, inlet and natural pools. ',
                'Actividades acuáticas en tres ríos subterráneos (incluye bolsa de seguridad para el recorrido).',
                'Espectáculos y exhibiciones (Charrería, Voladores de Papantla y Danzas Prehispánicas).',
                'La nueva presentación de “Xcaret México Espectacular”.',
                'Visitas al Acuario de Arrecife Coral, el Mariposario, el Aviario y la Isla de los Jaguares.',
                'Acceso a la taquilla especial por comprar en línea.',
                'Chalecos salvavidas y llantas flotantes.',
                'Camastros, hamacas y áreas de descanso.',
                'Sanitarios, vestidores y regaderas.',
                'Estacionamiento gratuito.',
                'Otros servicios (WiFi, cajeros automáticos y tiendas).',
                'Tu Tour incluye transportación solo si la agregas al momento de tu compra.',
            ]
        },
        recommendations: {
            titleRecommendations: 'Recommendations',
            contentRecommendations: [
                '¡All the photos of your experience at an special price! By including your photo pass before your visit you get an exclusive price.',
                'Recuerda traer impreso el comprobante de compra de tu admisión al Parque.',
                'Usa ropa cómoda de playa y traje de baño, trae una muda extra.',
                'Si el tour es con transportación, confirma la hora y lugar de salida .',
                '¡Ayúdanos a cuidar y preservar nuestro ecosistema! Para no afectar el balance natural del ecosistema, en el Parque solo está permitido el uso de bloqueadores y bronceadores que contengan óxido de titanio y óxido de zinc. En caso de no cumplir con este requisito, a la entrada del Parque encontrarás un módulo de información donde podrás intercambiar tu bloqueador por una muestra libre de químicos; tu bloqueador te será devuelto a la salida.',
                'Lleva efectivo o tarjeta para comprar souvenirs, realizar alguna actividad opcional o adquirir las fotos de tu visita.',
            ]
        },
        important: {
            titleImportant: 'Important Information',
            contentImportant: [
                'Xcaret Park opens 365 days a year from 8:30 a.m. to 9:30 p.m.',
                'Niños menores de 4 años entran gratis.',
                'Niños de 5 a 11 años tienen 25% de descuento sobre el precio de adulto (indispensable presentar identificación en las taquillas del Parque).',
                'Niños mayores a 1.40 m pero menores de 12 años, deberán presentar identificación para comprobar su edad.',
                'Las reservaciones canceladas con más de 2 días de antelación a la fecha de llegada, están sujetas a un cargo del 10% por gastos administrativos; de 2 a 0 días antes de la fecha de llegada, o en caso de no llegar, no serán reembolsables.',
                'No es posible hacer el cambio de fecha el mismo día de tu visita.',
                'La renta del equipo de snorkel tiene un costo de $10 USD (se requiere de un depósito reembolsable de $ 25 USD).',
                'Para los visitantes con admisión Xcaret Plus (con alimentos) el uso del equipo de snorkel está incluido (requiere un depósito reembolsable de $ 10 USD).',
            ]
        }
    }
};
