import { Component, OnInit } from '@angular/core';
import { ScreenDetectorService } from '../../../services/screenDetector/screenDetector.service';

@Component({
    selector: 'app-footer-comp',
    templateUrl: './footer.component.html',
    styleUrls: [ './footer.component.styl' ]
})
export class FooterComponent implements OnInit {

    constructor(public scnSz: ScreenDetectorService) {
    }

    ngOnInit() {
    }
}
