import { Component } from '@angular/core';

@Component({
    selector: 'app-404-comp',
    templateUrl: './404.component.html',
    styleUrls: [ './404.component.styl' ]
})
export class PageNotFoundComponent {

    constructor() { }

}
