export const XENOTES = {
    es: {
        location: 'Ficha Xenotes Oasis ',
        titulo: 'TOUR XENOTES OASIS ',
        imagen: 'slide-xenotes.jpg',
        hybridConf: {
            'baseProduct': 'XTXEOM_1_11',
            'upgrades': {
                'P': 'PAQF1P_0_5_0'
            }
        },
        info: {
            titleDescripcion: 'Tan natural como tu personalidad',
            contentDescripcion: [
                'En el Tour Xenotes tendrás un día repleto de actividades de aventura. Conoce los cuatro distintos tipos de cenotes que existen, que son pozas naturales formadas hace millones de años y que conectan el sistema de ríos subterráneos más grande del mundo.',
                'Realiza un descenso en rappel asistido, deslízate en una tirolesa, pasea en kayak y nada en un ambiente relajante para conectarte con la naturaleza.',
                'En el Tour Xenotes tienes una atención más personalizada y mayor comodidad al ser grupos pequeños. Para recuperar tus energías, disfruta de un delicioso picnic que puedes acompañar con una copa de vino o cerveza.'
            ]
        },
        includes: {
            titleIncluye: 'Incluye',
            contentIncluye: [
                'Transportación en viaje redondo con guía especializado.',
                'Visita a cuatro diferentes tipos de cenotes.',
                'Actividades de rappel asistido, tirolesas, kayak y nado de exploración acuática.',
                'Bebidas sin alcohol (champurrado, café, agua y refrescos), frutas de la estación y pan dulce a la salida del primer cenote.',
                'Picnic que incluye: sopa estilo minestrone, barra fría de carnes y quesos premium, servidos con una variedad de panes rústicos y aderezos, para preparar tus propios alimentos acompañados con ensaladas.',
                'Agua, café, vino y cerveza a elegir durante el picnic.',
                'Equipo de rappel, kayak, llanta y chaleco salvavidas.',
                'Uso de toallas, vestidores y sanitarios.',
            ]
        },
        recommendations: {
            titleRecommendations: 'Recomendaciones',
            contentRecommendations: [
                'Recuerda traer impreso el comprobante de compra de tu tour.',
                'Confirma el lugar y horario del pick up.',
                'Trae zapatos para agua, traje de baño y un cambio de ropa.',
                'Evita el uso de maquillaje, perfumes o repelentes con químicos que puedan afectar el ecosistema de los cenotes, se debe tomar una ducha antes de entrar al primer cenote.',
                'Evita salir de los caminos marcados o preestablecidos para prevenir algún incidente con la fauna o flora del lugar.',
                'Si observas algún animal, evita tocarlo, acercarte o alimentarlo, están en su hábitat natural.',
                'Lleva tarjeta de crédito o efectivo para adquirir las fotografías del Tour.',
            ]
        },
        important: {
            titleImportant: 'Información Importante',
            contentImportant: [
                'El Tour Xenotes está disponible de lunes a sábado.',
                'La edad mínima para Xenotes es de 6 años. Niños entre 6 y 11 años tienen 25% de descuento sobre el precio de adulto (indispensable presentar identificación en las taquillas del Parque). NOTA: para la actividad de tirolesas se requiere una altura mínima de 1.40 m y un peso mínimo de 40 kg.',
                'El uso del chaleco salvavidas es obligatorio durante todas las actividades acuáticas.',
                'Este tour no es recomendado para mujeres embarazadas o si tienes problemas de corazón, pulmones o columna, así como asma, diabetes o hipertensión.',
                'Toma en cuenta que los cenotes se encuentran ubicados en el medio de la selva, por lo que algunos caminos para llegar a ellos no están pavimentados.',
                'Las reservaciones canceladas con más de 2 días de antelación a la fecha de llegada, están sujetas a un cargo del 10% por gastos administrativos; de 2 a 0 días antes de la fecha de llegada, o en caso de no llegar, no serán reembolsables.',
                'No es posible hacer cambio de fecha el mismo día de tu visita.',
            ]
        }
    },
    en: {
        location: 'Xenotes Oasis',
        titulo: 'XENOTES TOUR',
        imagen: 'slide-xenotes.jpg',
        info: {
            titleDescripcion: 'Fun, nature and culture for all the family in Xcaret Park',
            contentDescripcion: [
                'Xcaret its a natural park with more than 50 attractions for all ages, perfect to go with all the family.',
                'Here you can swim in underground rivers, pasear por senderos en medio de la jungla y recorrer cuevas misteriosas. Descubre nuevos colores en el Mariposario y los misterios del fondo marino en el Acuario de Arrecife de Coral. Admira de cerca venados, jaguares, flamingos y aves exóticas.',
                'En Xcaret México hay diversos atractivos para visitar, como los vestigios precolombinos, el Museo de Arte Popular Mexicano, una clásica hacienda henequenera y la capilla de Guadalupe.',
                'Además disfruta de la cultura mexicana con las danzas prehispánicas, la charrería y la magnífica presentación de Xcaret México Espectacular, con más de 300 artistas en escena.',
                'En Xcaret Riviera Maya hay una amplia variedad de restaurantes para elegir. Y para tu mayor comodidad, tenemos áreas de descanso, vestidores, duchas y sanitarios, ubicados en distintas áreas del parque.',
                '¡Ven a Xcaret y vive con tu familia la experiencia más inolvidable de sus vacaciones!',
            ]
        },
        includes: {
            titleIncluye: 'Includes',
            contentIncluye: [
                'Access to the beach, inlet and natural pools. ',
                'Actividades acuáticas en tres ríos subterráneos (incluye bolsa de seguridad para el recorrido).',
                'Espectáculos y exhibiciones (Charrería, Voladores de Papantla y Danzas Prehispánicas).',
                'La nueva presentación de “Xcaret México Espectacular”.',
                'Visitas al Acuario de Arrecife Coral, el Mariposario, el Aviario y la Isla de los Jaguares.',
                'Acceso a la taquilla especial por comprar en línea.',
                'Chalecos salvavidas y llantas flotantes.',
                'Camastros, hamacas y áreas de descanso.',
                'Sanitarios, vestidores y regaderas.',
                'Estacionamiento gratuito.',
                'Otros servicios (WiFi, cajeros automáticos y tiendas).',
                'Tu Tour incluye transportación solo si la agregas al momento de tu compra.',
            ]
        },
        recommendations: {
            titleRecommendations: 'Recommendations',
            contentRecommendations: [
                '¡All the photos of your experience at an special price! By including your photo pass before your visit you get an exclusive price.',
                'Recuerda traer impreso el comprobante de compra de tu admisión al Parque.',
                'Usa ropa cómoda de playa y traje de baño, trae una muda extra.',
                'Si el tour es con transportación, confirma la hora y lugar de salida .',
                '¡Ayúdanos a cuidar y preservar nuestro ecosistema! Para no afectar el balance natural del ecosistema, en el Parque solo está permitido el uso de bloqueadores y bronceadores que contengan óxido de titanio y óxido de zinc. En caso de no cumplir con este requisito, a la entrada del Parque encontrarás un módulo de información donde podrás intercambiar tu bloqueador por una muestra libre de químicos; tu bloqueador te será devuelto a la salida.',
                'Lleva efectivo o tarjeta para comprar souvenirs, realizar alguna actividad opcional o adquirir las fotos de tu visita.',
            ]
        },
        important: {
            titleImportant: 'Important Information',
            contentImportant: [
                'Xcaret Park opens 365 days a year from 8:30 a.m. to 9:30 p.m.',
                'Niños menores de 4 años entran gratis.',
                'Niños de 5 a 11 años tienen 25% de descuento sobre el precio de adulto (indispensable presentar identificación en las taquillas del Parque).',
                'Niños mayores a 1.40 m pero menores de 12 años, deberán presentar identificación para comprobar su edad.',
                'Las reservaciones canceladas con más de 2 días de antelación a la fecha de llegada, están sujetas a un cargo del 10% por gastos administrativos; de 2 a 0 días antes de la fecha de llegada, o en caso de no llegar, no serán reembolsables.',
                'No es posible hacer el cambio de fecha el mismo día de tu visita.',
                'La renta del equipo de snorkel tiene un costo de $10 USD (se requiere de un depósito reembolsable de $ 25 USD).',
                'Para los visitantes con admisión Xcaret Plus (con alimentos) el uso del equipo de snorkel está incluido (requiere un depósito reembolsable de $ 10 USD).',
            ]
        }
    }
};
